<template>
  <form @submit.prevent="saveItem">
    <b-card>
      <b-row>
        <b-col cols="12" md="6">
          <div class="form-group">
            <label for="title">Title:</label>
            <input
              id="title"
              type="text"
              class="form-control"
              v-model="formPayload.other.title"
            />
          </div>
          <div class="form-group">
            <label for="note">Note:</label>
            <textarea
              id="note"
              class="form-control"
              v-model="formPayload.other.note"
              style="height: 200px"
              maxlength="500"
            />
          </div>
          <div class="form-group">
            <label for="file" class="mb-0">Image:</label>
            <b-form-file
              id="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              ref="fileInput"
              accept="image/png, image/jpeg, image/jpg"
              @change="handleFileImage($event)"
            />
            <div v-if="previewImage" class="mt-1 mb-4">
              <img :src="previewImage" class="w-100 product__image rounded" />
            </div>
          </div>
        </b-col>

        <b-col cols="12" md="6">
          <div class="form-group">
            <label for="sub_title">Sub Title:</label>
            <input
              id="sub_title"
              type="text"
              class="form-control"
              v-model="formPayload.other.sub_title"
            />
          </div>
          <div class="form-group">
            <label for="invitation_sentence">Invitation Sentence:</label>
            <textarea
              id="invitation_sentence"
              class="form-control"
              v-model="formPayload.other.invitation_sentence"
              style="height: 200px"
              maxlength="500"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-col cols="12" class="p-0 mt-2">
      <template v-if="isLoading">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
          disabled
        >
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </button>
      </template>
      <template v-else>
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
      </template>
    </b-col>
  </form>
</template>

<script>
import { TrashIcon } from "vue-feather-icons";
import { quillEditor } from "vue-quill-editor";
import {
  BCard,
  BCardText,
  BForm,
  BButton,
  BCol,
  BRow,
  BSpinner,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BButton,
    BCol,
    BRow,
    BSpinner,
    BFormFile,
    BFormTextarea,
    TrashIcon,
    quillEditor,
  },

  data() {
    return {
      formPayload: {
        type: "female-future-leader-continue-register",
        other: {
          image: null,
          title: "",
          sub_title: "",
          invitation_sentence: "",
          note: "",
        },
      },
      previewImage: null,
      isLoading: false,
      validations: "",
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http
        .get("/api/v1/admin/page/female-future-leader-continue-register")
        .then((response) => {
          this.formPayload = response.data.data;
          if (this.formPayload.other.image !== null) {
            this.previewImage = this.formPayload.other.image;
          }
          this.isLoading = false;
        });
    },
    addForm() {
      this.formPayload.other.requirements.push("");
    },
    removeItem(index) {
      this.formPayload.other.requirements.splice(index, 1);
    },
    handleFileImage(event) {
      const file = event.target.files[0];
      this.formPayload.other.image = file;
      this.showImage(event.target.files[0]);
    },
    showImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    saveItem() {
      this.isLoading = true;
      const payload = new FormData();
      payload.append("type", this.formPayload.type);
      payload.append("other[title]", this.formPayload.other.title);
      payload.append("other[sub_title]", this.formPayload.other.sub_title);
      payload.append("other[invitation_sentence]", this.formPayload.other.invitation_sentence);
      payload.append("other[note]", this.formPayload.other.note);
      payload.append("other[image]", this.formPayload.other.image);
      this.$http
        .post(`/api/v1/admin/page/female-future-leader-continue-register`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          successNotification(
            this,
            "Success",
            "Female Future Leader Continue Register Berhasil di update!"
          );
          this.isLoading = false;
          this.getData();
        })
        .catch((error) => {
          if (error.response.data.meta.messages) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
.product__icon {
  width: 24px;
  height: 24px;
}
.product__image {
  height: 300px;
  object-fit: contain;
}
</style>
